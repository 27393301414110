import * as React from "react"

import Layout from "../components/layout"
import Researcher from "../components/researcher"
import Seo from "../components/seo"
const logo = require("../images/logo.svg").default

const ResearcherPage = () => (
  <Layout
    backgroundColor="white"
    logo={logo}
    hamburgerColor="black"
    menuBGColor="black"
    footerColor="rgb(21, 34, 35)"
  >
    <Seo title="Rajyashree Ramesh" />
    <Researcher />
  </Layout>
)

export default ResearcherPage

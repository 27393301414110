import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import {
  CardTitle,
  ImageCardContainer,
  ImageFadeIn,
  ImageScaleTransition,
  ImgTitle,
} from "../commons/HighlightsCards.style"
import {
  PosterImageContainer,
  PosterTitle,
} from "../commons/PosterContainer.style"
import { Title, Wrapper } from "../performer/performerStyles"
import { PageDescriptionContainer } from "../practitioner/practitionerstyles"
import {
  ResearcherCaption,
  RectangleCenterDown,
  ImageContainer,
  ModalWrapper,
  Overlay,
  ModalContent,
  CloseButton,
  ModalHeading,
  ModalListContainer,
  ModalListElement
} from "./researcherstyles"

interface Event {
  heading: string
  description: string[]
}
const events: Event[] = [
  {
    heading: "Lectures, Lecture-Performances and Workshops",
    description: [
      "2015 - Lecture-performance 'From Bharata to Bartenieff' at Laban Conference, Hamburg",
      "2015 - Lecture-performance and lectures in seminar 'Leib und Berühren' at MA Religion und Kultur, Humboldt-University Berlin",
      "2014 - Invited for lecture and discussion with Prof. Gabriele Brandstetter on 'Zeitgeist and Tradition in Indian Dance' at Ballett-Universität Gesprächssalon at State Opera Berlin",
      "2014 - Lecture-performance in seminar 'Zu den Quellen' at MA Religion und Kultur, Humboldt-University Berlin",
      "2013 - Workshop: Movement analytic approach to Indian dance for Master students at Dance Department, University of Hyderabad, India",
      "2012 - Workshop at Festival of the Moving Body at Stonybrook University, USA",
      "2011 - Workshop at 'Dance Yatre' – a dance festival organised by Vyjayanthi Kashi in Bangalore",
      "2010 - Lecture 'Cognitive Semiotics of Natya' at University of Hyderabad",
      "2010 - Performance (Goethe's Iphigenie) and Lecture at ISGS Conference, Europa-University Viadrina, Germany",
      "2009 - Lecture-Performance at Gesture Research Conference of Freie Universität Berlin at House of World Cultures Berlin",
      "2009 - Lecture-Performance at Interdisciplinary Symposium – New Perspectives in Dance and Cognitive Science ,Bielefeld Universität",
    ],
  },
  {
    heading: "Symposiums, Lecture-Performances and Workshops",
    description: [
      "2015 - Lecture-performance 'From Bharata to Bartenieff' at Laban Conference, Hamburg",
      "2015 - Lecture-performance and lectures in seminar 'Leib und Berühren' at MA Religion und Kultur, Humboldt-University Berlin",
      "2014 - Invited for lecture and discussion with Prof. Gabriele Brandstetter on 'Zeitgeist and Tradition in Indian Dance' at Ballett-Universität Gesprächssalon at State Opera Berlin",
      "2014 - Lecture-performance in seminar 'Zu den Quellen' at MA Religion und Kultur, Humboldt-University Berlin",
      "2013 - Workshop: Movement analytic approach to Indian dance for Master students at Dance Department, University of Hyderabad, India",
      "2012 - Workshop at Festival of the Moving Body at Stonybrook University, USA",
      "2011 - Workshop at 'Dance Yatre' – a dance festival organised by Vyjayanthi Kashi in Bangalore",
      "2010 - Lecture 'Cognitive Semiotics of Natya' at University of Hyderabad",
      "2010 - Performance (Goethe's Iphigenie) and Lecture at ISGS Conference, Europa-University Viadrina, Germany",
      "2009 - Lecture-Performance at Gesture Research Conference of Freie Universität Berlin at House of World Cultures Berlin",
      "2009 - Lecture-Performance at Interdisciplinary Symposium – New Perspectives in Dance and Cognitive Science at Bielefeld Universität",
    ],
  },
  {
    heading: "Published Articles, Lecture-Performances and Workshops",
    description: [
      "2015 - Lecture-performance 'From Bharata to Bartenieff' at Laban Conference, Hamburg",
      "2015 - Lecture-performance and lectures in seminar 'Leib und Berühren' at MA Religion und Kultur, Humboldt-University Berlin",
      "2014 - Invited for lecture and discussion with Prof. Gabriele Brandstetter on 'Zeitgeist and Tradition in Indian Dance' at Ballett-Universität Gesprächssalon at State Opera Berlin",
      "2014 - Lecture-performance in seminar 'Zu den Quellen' at MA Religion und Kultur, Humboldt-University Berlin",
      "2013 - Workshop: Movement analytic approach to Indian dance for Master students at Dance Department, University of Hyderabad, India",
      "2012 - Workshop at Festival of the Moving Body at Stonybrook University, USA",
      "2011 - Workshop at 'Dance Yatre' – a dance festival organised by Vyjayanthi Kashi in Bangalore",
      "2010 - Lecture 'Cognitive Semiotics of Natya' at University of Hyderabad",
      "2010 - Performance (Goethe's Iphigenie) and Lecture at ISGS Conference, Europa-University Viadrina, Germany",
      "2009 - Lecture-Performance at Gesture Research Conference of Freie Universität Berlin at House of World Cultures Berlin",
      "2009 - Lecture-Performance at Interdisciplinary Symposium – New Perspectives in Dance and Cognitive Science at Bielefeld Universität",
    ],
  },
]

// console.log(events)

const Researcher = () => {
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState<Event | null>(null)

  const toggleModal = (event: Event) => {
    if (!modal) {
      setModalData(event)
    }
    setModal(!modal)
    if (!modal) {
      document.body.classList.add("active-modal")
    } else {
      document.body.classList.remove("active-modal")
    }
  }

  return (
    <React.Fragment>
      <PosterTitle color="#00000">RESEARCHER</PosterTitle>
      <PageDescriptionContainer backgroundColor="rgb(21, 34, 35)">
        <PosterImageContainer width="815px">
          <ImageFadeIn>
            <StaticImage
              src="../../images/Researcher/researchernew.png"
              alt=""
              placeholder="dominantColor"
            />
            <CardTitle>
              A movement-based performing arts perspective on embodied meaning and 
              cognition.
            </CardTitle>
          </ImageFadeIn>
        </PosterImageContainer>
      </PageDescriptionContainer>

      <ResearcherCaption>
        <p>
          Triggered by a study of human movement as Laban/Bartenieff Movement
          Analyst Rajyashree Ramesh channelised her expertise in dance and
          movement into academic research, receiving a doctorate from
          Europa-University Viadrina, Germany, for her extensive
          cross-disciplinary analysis of the theory and practice of the Indian
          performing arts Nāṭya from the perspectives of Linguistic Gesture
          Studies, Cognitive Linguistics, Brain Research on emotions and
          movement studies. And in instantiating a dialogue between these
          fields, with implications for Embodiment Studies, Dance/ Movement
          Studies, and finally Cultural Studies, opening a new research field,
          as her thesis was reviewed. Her research addressed questions on
          embodied meaning and cognition from a movement and performing arts
          perspective, in particular from the perspective of a long standing
          tradition, thus opening new ground for the potential Indian performing
          arts hold both in practice and theory for current fields of research.
          By taking an exemplary top-down to bottom-up stance, she traces the
          primordial role of movement and emotions for embodiment and body-brain
          processes, unveiling thereby the very nature of primary movement
          emergent inner to outer in emotive-kinetic processes. The practical
          implications of this research constitute the Movement Studies
          programmes she offers, ushering in new perspectives for not only
          performance, but also dance/movement education and therapy.
        </p>
        <p style={{ marginBottom: "200px" }}>
          Doctoral Thesis :
          <a
            href="https://opus4.kobv.de/opus4-euv/frontdoor/index/index/docId/485"
            target="_blank"
            className="anchor-element"
          >Sensing and Shaping – the emotive-kinetic grounding
          of meaning. A cross-disciplinary analysis of Indian Dance Theatre.
          </a>
          
        </p>
        <RectangleCenterDown>
          <StaticImage
            src="../../images/Researcher/RectangleCenter.png"
            alt="RectangleLeft"
            placeholder="dominantColor"
          />
        </RectangleCenterDown>
      </ResearcherCaption>

      <Wrapper>
        <Title>
          Taking dance knowledge and in-depth study of the intricacies of human
          movement as a Certified Laban/Bartenieff Movement Analyst into academic
          research.
        </Title>
      </Wrapper>

      <ImageCardContainer gap="20px">
        <ImageContainer onClick={() => toggleModal(events[0])}>
          <ImageScaleTransition>
            <StaticImage
              src="../../images/Researcher/researcher1.png"
              alt="researcher"
              placeholder="dominantColor"
            />
            <CardTitle>
              <ImgTitle>lectures</ImgTitle>
            </CardTitle>
          </ImageScaleTransition>
        </ImageContainer>

        <ImageContainer onClick={() => toggleModal(events[1])}>
          <ImageScaleTransition>
            <StaticImage
              src="../../images/Researcher/researcher2.png"
              alt="researcher"
              placeholder="dominantColor"
            />
            <CardTitle>
              <ImgTitle>symposiums</ImgTitle>
            </CardTitle>
          </ImageScaleTransition>
        </ImageContainer>

        <ImageContainer onClick={() => toggleModal(events[2])}>
          <ImageScaleTransition>
            <StaticImage
              src="../../images/Researcher/researcher3.png"
              alt="researcher"
              placeholder="dominantColor"
            />
            <CardTitle>
              <ImgTitle>published articles</ImgTitle>
            </CardTitle>
          </ImageScaleTransition>
        </ImageContainer>
      </ImageCardContainer>

      <ModalWrapper showModal={modal}>
        <Overlay onClick={() => toggleModal(events[0])} />
        <ModalContent>
          <ModalHeading>{modalData?.heading}</ModalHeading>
          <ModalListContainer>
            {modalData?.description.map((item, index) => (
              <ModalListElement key={index}>{item}</ModalListElement>
            ))}
          </ModalListContainer>
          <CloseButton onClick={() => toggleModal(events[0])}>
          &times;
          </CloseButton>
        </ModalContent>
      </ModalWrapper>
    </React.Fragment>
  )
}

export default Researcher
